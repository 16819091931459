.modal-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0000008a;
  z-index: 2;

  .modal-content {
    position: absolute;
    z-index: 30;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #2c2c2c;
    border-radius: 12px;
  }
}
